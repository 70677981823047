import "../german-definition/german_definition.css"
import React from "react";
import {useState,useEffect} from "react";
import Button from "../../components/button/button";
import Slider from "../game_description/components/Slider/Slider";
import axios from "axios";
import GermanDefinitionSlider from "../../pages/german-definition/components/germanDefinitionSlider"
import ModalScoreEngGer from "./components/ModalScoreEngGer";

export default function GermanDefinition(props){
    let [data, setData] = useState([])
    let [index, setIndex] = useState(1);
    let [userIdParams, setUserIdParams]=useState('')
    let [userNameParams, setUserNameParams]=useState('')
    let [roleParams, setRoleParams]=useState('')
    const [isScoreVisible, setIsScoreVisible] = useState(false)
    const [score, setScore] = useState(0);
    const [totalScore, setTotalScore] = useState(20);


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        const userid = queryParams.get('userid');
        const username = queryParams.get('username');
        const role = queryParams.get('role');
        const mode = queryParams.get('mode');
        setUserIdParams(userid)
        setUserNameParams(username)
        setRoleParams(role)
        getDataGermanCarousel(id,mode)
    }, [])

    let getDataGermanCarousel = async (id,mode) => {
        try {
            let response = await axios.get(`http://app.englischvokabeltraining.com//services/VocabulariesService.php?url=getGameDescription&id=${id}&mode=${mode}`)
            setData([...response.data])
            console.log(response.data, "etgferf")
        } catch (e) {
            console.log(e, `error`)
        }
    }

    let newGame = async () => {
        console.log("etet")
        if (userIdParams && userNameParams && roleParams) {
            window.location.assign(`http://app.englischvokabeltraining.com/firstPage.php?userid=${userIdParams}&username=${userNameParams}&role=${roleParams}`);
        }
    }

    let indexCarousel =(index) => {
        setIndex(index)
    }

    let modalVisScore = () => {
        setIsScoreVisible(true)
    }
    let modalVisScoreClose = () => {
        setIsScoreVisible(false)
    }
    let checkScore = () =>{
        console.log("tutu")
        setIsScoreVisible(true)
    }

    return(
        <>  {
            data.length ?
                <div className="game_desc_container">
                    <div className="container">
                        <div className="new_game_score_button">
                            <Button
                                className="new_game_button"
                                title="New Game"
                                onClick={newGame}
                            />
                            <Button
                                className="new_game_button"
                                title="Score"
                                onClick={checkScore}
                            />
                        </div>
                        <ModalScoreEngGer isScoreVisible={isScoreVisible} setIsScoreVisible={setIsScoreVisible} modalVisScore={modalVisScore}
                                    modalVisScoreClose={modalVisScoreClose} score={score} totalScore={totalScore} checkScore={checkScore}/>

                        <GermanDefinitionSlider data={data} indexCarousel={indexCarousel}/>
                        <div className="game_desc_buttons_block">
                            <Button
                                className="previouse_button"
                                title={"Previous"}
                            />
                            <div className="scores_count_">{index}/{data.length}</div>
                            <Button
                                className="next_button"
                                title={"Next"}
                            />
                        </div>
                    </div>
                </div>
                :
                null
        }
        </>

    )
}