import React from "react";

import "./button.css"

export default function Button(props) {
    return(
        <button
            className={`start_again ${props.className}`}
            onClick={props.onClick}
        >
            {props.title}
       </button>
    )

}
