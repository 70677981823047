import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import React from "react";
import GameVocabMatch from "./pages/game/game";
import {  DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
// import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import GameDescription from "./pages/game_description/game_description";
import "./pages/game_description/components/Slider/SliderStyles.css"
import GermanDefinition from "./pages/german-definition/german_definition";


function App() {
    return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch} >
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GameVocabMatch/>}/>
                        <Route path="/gameDescription" element={<GameDescription/>}/>
                        <Route path="/germanDefinition" element={<GermanDefinition/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </DndProvider>
    );
}

export default App;
