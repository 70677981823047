import React, { useState, useEffect } from "react";
import song_icon from "../assets/images/song_icon.png"
import Sound from "react-sound"

// const useAudio = url => {
//     const [audio] = useState(new Audio(url));
//     const [playing, setPlaying] = useState(false);
//
//     const toggle = () => {
//         alert("click")
//
//         setPlaying(!playing);
//     }
//
//     useEffect(() => {
//             playing ? audio.play() : audio.pause();
//         },
//         [playing]
//     );
//
//     useEffect(() => {
//         audio.addEventListener('ended', () => setPlaying(false));
//         return () => {
//             audio.removeEventListener('ended', () => setPlaying(false));
//         };
//     }, []);
//
//     return [playing, toggle];
// };

const Player = ({ soundUrl }) => {
    //const [playing, toggle] = useAudio(soundUrl);
   // console.log(soundUrl);
    const [platStatus, setPlayStatus] = useState('STOPPED')

    let handleSongPlaying = () => {
        setPlayStatus('PLAYING')
    }

    let handleSongFinishedPlaying = () => {
        setPlayStatus('STOPPED')
    }

    return (
        <div>
            <button onClick={handleSongPlaying} className="audio_button"><img src={song_icon} alt=""/></button>
            {/*{playing ? "Pause" : "Play"}*/}
    <Sound
        url={soundUrl}
        playStatus={platStatus}
        playFromPosition={300}
        volume={100}
        onPlaying={handleSongPlaying}
        onFinishedPlaying={handleSongFinishedPlaying}
    />
        </div>
    );
};

export default Player;