import React, {useEffect, useState, useRef} from "react";
import {Carousel} from '3d-react-carousal';
import refresh_icon from "../../../assets/images/refresh.png"
import song_icon from "../../../assets/images/song_icon.png"
import Player from "../../../components/Audio";
import "../../game_description/components/Slider/SliderStyles.css"


export default function GermanDefinitionSlider(props) {
    let [score, setScore] = useState(1)
    let [totalScore, setTotalScore] = useState(props.data.length)
    let [sliderFrontData, setSliderFrontData]=useState([])
    let [sliderBackData, setSliderBackData]=useState([])
    let [slides, setSlides] = useState([])
    let flipBtn = useRef(null)
    let flipBtn2 = useRef(null)

    let refCarusel = useRef()
    let rotate = (i)=>{
        document.querySelector(".flip-btn").classList.remove("focus");
        let item = document.getElementById(i);
        if (!item.classList.contains('focus')){
            item.classList.add("focus")
        }else{
            item.classList.remove("focus")

        }
    }


    let onChangeSliderBack = (word_id,definition_id) => {
        console.log("gfhgfh")
       let arr=[]
        let indexFrontWord = sliderFrontData.findIndex(item => item.id === word_id)
        let indexBackWord = sliderBackData.findIndex(item =>item.id === definition_id )
        if (indexBackWord === indexFrontWord){
            // alert("true")
        }
    }

    let createSlides = () => {
        let active = null;
        let arrDates = props.data.map((item, index) => {
            return (
                <div key={index}>
                    <button className={"flip-btn "} id={index} ref={flipBtn} onClick={()=>rotate(index)} tabIndex="1"><img src={refresh_icon} alt=""
                                                                                                                           className="refresh_icon"/> German
                    </button>
                    <div className={`flip-card`}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <div className="card">
                                    <div className="card_inner">
                                        <div className="word">{item.word}</div>
                                        {/*<div className="sound">*/}
                                        {/*    <Player soundUrl={item.soundUrl}/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="flip-card-back">
                                <div className="card_inner_back">
                                    <textarea name="" id="" cols="30" rows="10" className="textarea" onChange={onChangeSliderBack}> </textarea>
                                    {/*<div className="word">{item.definition}</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        setSlides([...arrDates])
    }

    useEffect(() => {
        createSlides()
    }, [])

    return (
        <div>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                  integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
                  crossOrigin="anonymous"/>

                    <Carousel
                        ref={refCarusel}
                        slides={slides}
                        autoplay={false}
                        interval={1000}
                        totalSlides={1}
                        onSlideChange={(index) => {
                            props.indexCarousel(index+1)
                        }}
                    />


        </div>
    )
}