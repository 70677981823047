import "./game.css"
import React, {useState, useEffect} from "react";
import Button from "../../components/button/button";
import BasketComp from "./Basket";
import {useParams} from "react-router-dom";
import Explanations from "./explanations";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import ScoreModal from "./Modal_Score";
import Slider from "../game_description/components/Slider/Slider";
import GameDescription from "../game_description/game_description";


export default function GameVocabMatch(props) {
    let [dataExplanation, setDataExplanation] = useState([]);
    let [cardExplanation, setCardExplanation] = useState([]);
    let [cards, setCards] = useState([]);
    const [score, setScore] = useState(0);
    const [totalScore, setTotalScore] = useState(10);
    const [error, setError] = useState('');
    let [idParams, setIdParams] = useState('');
    let [userIdParams, setUserIdParams] = useState('');
    let [userNameParams, setUserNameParams] = useState('');
    let [roleParams, setRoleParams] = useState('');
    let [modeParams, setModeParams] = useState('');
    const [isScoreVisible, setIsScoreVisible] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        const userid = queryParams.get('userid');
        const username = queryParams.get('username');
        const role = queryParams.get('role');
        const mode = queryParams.get('mode');
        setIdParams(id)
        setUserIdParams(userid)
        setUserNameParams(username)
        setRoleParams(role)
        setModeParams(mode)
        getData(id, mode)
    }, [])


    let getData = async (id, mode) => {
        try {
            let response = await axios.get(`http://app.englischvokabeltraining.com/services/VocabulariesService.php?url=getMatch&id=${id}&mode=${mode}`)
            if (response.data.answer) {
                console.log(response)
                setCardExplanation(response.data.answer)
                setDataExplanation(response.data.answer)

                let arr = []
                response.data.question.map(data => {
                    arr.push({
                        id: data.id,
                        name: data.answer,
                        answer: '',
                        answer_id: null,
                        check: false
                    })
                })
                setCards(arr)
            }
        } catch (e) {
            console.log(e, `error`)
        }
    }

    let newGame = async () => {
        if (userIdParams && userNameParams && roleParams) {
            window.location.assign(`http://app.englischvokabeltraining.com/firstPage.php?userid=${userIdParams}&username=${userNameParams}&role=${roleParams}`);
        }
    }


    let onChange = (basket, cards_id) => {
        let arr = []
        let index = cards.findIndex(item => item.id === cards_id)
        cardExplanation.map(data => {
            let flag = false
            basket.some(item => {
                if (data.id === item.id) {
                    flag = true
                    return true
                }
            })
            if (!flag) {
                arr.push(data)
            }
        })
        setCardExplanation([...arr])

        let arrCards = cards
        let indexCopy = arrCards.findIndex(item => item.answer_id === basket[0].id)
        if (indexCopy !== -1) {
            arrCards[indexCopy].answer_id = null
            arrCards[indexCopy].answer = ''
        }
        arrCards[index].answer_id = basket[0].id
        cards[index].answer = basket[0].name
        setCards([...arrCards])

    }

    const clearGame = () => {
        let arr = cards
        arr.forEach(data => {
            data.check = false
            data.answer_id = null
            data.answer = ''
        })
        setCards([...arr])
        setScore(0)
        setError("")
        setCardExplanation([...dataExplanation])
    }


    const checkScore = () => {
        let count = 0
        let arr = cards
        if (!cardExplanation.length) {
            arr.forEach(data => {
                data.check = true
                if (data.id === data.answer_id) {
                    count += 1
                }
            })
            setCards([...arr])
            setScore(count)
            setError("")
            setIsScoreVisible(true)
        } else {
            setError('Fill in all the fields')
        }
    }

    let modalVisScore = () => {
        setIsScoreVisible(true)
    }
    let modalVisScoreClose = () => {
        setIsScoreVisible(false)
    }

    return (
        <>
            {
                cards.length ?
                    <>
                        <div className="game_container">
                            <div className="container">
                                <h1 className="game_title">Vocab match</h1>
                                <div className="game_content_container">
                                    <BasketComp card={cardExplanation}/>
                                    <div className="game_content">
                                        <div className="game_content_inner">
                                            {
                                                cards.map((item, index) => {
                                                    return (
                                                        <div className="explanation—block" key={index}>
                                                            <Explanations onChange={onChange} item={item}
                                                                          cardExplanation={cardExplanation}/>
                                                            <div
                                                                className={`explanation—block1  ${cards[index].check ? (cards[index].id === cards[index].answer_id ? null : "empty") : null}`}>{item.name}</div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                                {error ? <p style={{color: "red", marginTop: "10px"}}>{error}</p> : null}
                                <div className="buttons_block">
                                        <Button
                                            className="start_again"
                                            title={'Start again'}
                                            onClick={() =>   getData(idParams, modeParams)}
                                        />
                                        <Button
                                            className="new_game"
                                            title={'New game'}
                                            onClick={newGame}
                                        />
                                       <Button
                                           className="score"
                                           title={'Clear'}
                                           onClick={clearGame}
                                       />
                                       <Button
                                           className="score"
                                           title={'Score'}
                                           onClick={checkScore}
                                       />
                                </div>
                                <ScoreModal isScoreVisible={isScoreVisible} setIsScoreVisible={setIsScoreVisible} modalVisScore={modalVisScore}
                                            modalVisScoreClose={modalVisScoreClose} checkScore={checkScore} score={score} totalScore={totalScore}/>
                            </div>
                        </div>


                        <div className="game_container_mobile">
                            <h1 className="game_title">Vocab match</h1>
                            <div className="game_content_container">
                                <div className="game_content">
                                    <div className="game_content_inner">
                                        {
                                            cards.map((item, index) => {
                                                return (
                                                    <div className="explanation—block" key={index}>
                                                        <div
                                                            className={`explanation—block1  ${cards[index].check ? (cards[index].id === cards[index].answer_id ? null : "empty") : null}`}>{item.name}</div>
                                                        <Explanations onChange={onChange} item={item}
                                                                      cardExplanation={cardExplanation}/>
                                                    </div>

                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                <BasketComp card={cardExplanation}/>
                            </div>
                            {error ? <p style={{color: "red", marginTop: "10px"}} className="error_fill">{error}</p> : null}
                            <div className="buttons_block">
                                <div className="buttons_block1">
                                    <Button
                                        className="start_again"
                                        title={'Start again'}
                                        onClick={() => getData(idParams, modeParams)}
                                    />
                                    <Button
                                        className="new_game"
                                        title={'New game'}
                                        onClick={newGame}
                                    />
                                </div>
                              <div className="buttons_block1">
                                  <Button
                                      className="score"
                                      title={'Clear'}
                                      onClick={clearGame}
                                  />
                                  <Button
                                      className="score"
                                      title={'Score'}
                                      onClick={checkScore}
                                  />
                              </div>
                            </div>
                            <ScoreModal isScoreVisible={isScoreVisible} setIsScoreVisible={setIsScoreVisible} modalVisScore={modalVisScore}
                                        modalVisScoreClose={modalVisScoreClose} score={score} totalScore={totalScore} />
                        </div>
                    </>
                    :
                    null
            }
        </>

    )
}

