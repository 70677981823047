import React from 'react'
import { useDrag } from 'react-dnd'


export const CardExplanation = ({ id, name, cards }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'pet',
        item: { id, name },
        collect: (monitor) => ({
             isDragging: monitor.isDragging()
        })
    })

    return (
        <div className={`item_explanation`}  ref={dragRef}>
            {name}
            {isDragging}
        </div>
    )
}