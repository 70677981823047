import React, {useEffect, useState} from "react";
import {CardExplanation} from "./WordCard";
import {useDrop} from "react-dnd";

export default function Explanations(props) {
    const [basket, setBasket] = useState([])
    const [{isOver}, dropRef] = useDrop({
        accept: 'pet',
        drop: (item) => setBasket((basket) => {
                if (!basket.length) {
                    return !basket.includes(item) ? [...basket, item] : basket
                }
                return basket
            }
        ),
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    })

    useEffect(() => {
        if (basket.length) {
            props.onChange(basket, props.item.id)
        }
    }, [basket])

    useEffect(() => {
        if (props.item.answer_id === null) {
            setBasket([...[]])
        }
    }, [props])



    return (
        <div className={`explanation—block2 ${!basket.length ? "empty" : "filled" }  ${props.item.check ? (props.item.id === props.item.answer_id  ? "right" : "wrong" ) : 'neutral'}`} ref={dropRef}>
            {basket.map((word, index) => <CardExplanation draggable key={index} id={word.id} name={word.name}
                                                          setBasket={setBasket}/>)}
            {/*{isOver && <div className="drop_here">Drop Here!</div>}*/}
        </div>
    )
}