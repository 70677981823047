import React, {useEffect, useRef, useState} from 'react'
import { useDrop } from 'react-dnd';
import { CardExplanation } from './WordCard';
import {Fragment} from "react";


const BasketComp = (props) => {
    let answercontainer = useRef(null);



    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    let result = shuffle(props.card)

    return (
            <Fragment>
                <div className="game_content_explanation" ref={answercontainer}>
                    <div className="game_content_explanation_inner">
                        <div className="explanations_items" >
                        {result.map((word,index) =>
                        <CardExplanation draggable id={word.id} name={word.name} key={index}/>)}
                            </div>
                        </div>
                </div>
            </Fragment>
    )
}
export default BasketComp