import React, {useState} from 'react';
import Modal from 'react-modal';
import "./game.css"
import close_icon from "../../assets/images/x-circle.png"


export default function ScoreModal(props){

    const customStyles= {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            Width: "100%",
            width: "calc(100%-30px)",
        },
    };

    return(
        <div>
            <Modal
                isOpen={props.isScoreVisible}
                onRequestClose={props.modalVisScoreClose}
                style={customStyles}
                ariaHideApp={false}
            >

<div className="score_modal_container">
    <div className="close_icon_div">
        <img src={close_icon} alt="close" className={"close_icon"}
             onClick={() => props.setIsScoreVisible(false)}/>
    </div>
        <p className="your_scores">Your score is</p>
        <div className="scores_count">{props.score}/{props.totalScore}</div>
</div>
            </Modal>

        </div>
    )
}