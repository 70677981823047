import "./game_desription.css"
import React, {useEffect} from "react";
import arrowLeft from "../../assets/images/chevrons-left.png"
import arrowRight from "../../assets/images/chevrons-right.png"
import Slider from "./components/Slider/Slider";
import {useState} from "react";
import Button from "../../components/button/button";
import axios from "axios";


export default function GameDescription(props) {
    let [userIdParams, setUserIdParams] = useState('');
    let [userNameParams, setUserNameParams] = useState('');
    let [roleParams, setRoleParams] = useState('');
    let [index, setIndex] = useState(1);
    let [data, setData] = useState([])


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        const userid = queryParams.get('userid');
        const username = queryParams.get('username');
        const role = queryParams.get('role');
        const mode = queryParams.get('mode');
        setUserIdParams(userid)
        setUserNameParams(username)
        setRoleParams(role)
        getDataCarousel(id,mode)
    }, [])


    let getDataCarousel = async (id,mode) => {
        try {
            let response = await axios.get(`http://app.englischvokabeltraining.com//services/VocabulariesService.php?url=getWordFinder&id=${id}&mode=definitions`)
            setData([...response.data])
            console.log(response.data)
            } catch (e) {
                console.log(e, `error`)
            }
    }


    let newGame = async () => {
        console.log("etet")
        if (userIdParams && userNameParams && roleParams) {
            window.location.assign(`http://app.englischvokabeltraining.com/firstPage.php?userid=${userIdParams}&username=${userNameParams}&role=${roleParams}`);
        }
    }

   let indexCarousel =(index) => {
        setIndex(index)
    }

    return (
        <>
            {
                data.length ?
                    <div className="game_desc_container">
                        <div className="container">
                            <div className="new_game_dest_block">
                                <Button
                                    className="new_game_dest"
                                    title="New Game"
                                    onClick={newGame}
                                />
                            </div>
                            <Slider data={data} indexCarousel={indexCarousel}/>
                            <div className="game_desc_buttons_block">
                                <Button
                                    className="previouse_button"
                                    title={"Previous"}
                                />
                                <div className="scores_count_">{index}/{data.length}</div>
                                <Button
                                    className="next_button"
                                    title={"Next"}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </>

    )
}